import Config from '../../../config';
import _ from 'lodash'

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    firstLoad: true,
    loaded: false,
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    isKycNew: false,
    selectedUser: {
        name: "",
        description: "",
        updatedAt: "",
        status: false
    },
    userKycStatus: null,
    kycStatusList: [
        'draft', 'progress', 'missing', 'approved', 'rejected'
    ],
    kycButtonFlow: {
        'initial': ['draft', 'progress'],
        'draft': ['progress'],
        'progress': ['missing', 'approved', 'rejected'],
        'missing': ['draft', 'progress'],
        'approved': ['approved'],
        'rejected': ['draft', 'progress'],
    },
    tempDocs: {},
    documentDisplay: {},
    userCreated: false,
    userUpdated: false,
    logoutRequired: false,
    userAction: null,
    userId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ],

}

export default function user_kyc(state = initState, action) {
    // console.log(action.type, action)
    let { tempDocs } = state

    switch (action.type) {
        case 'FETCH_KYCS':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case "FETCH_A_KYC":

            let boolIsKycNew = !action.payload.status ? true : false

            return {
                ...state,
                error: false,
                selectedUser: {
                    ...state.selectedUser,
                    ...action.payload
                },
                isKycNew: boolIsKycNew,
                userId: action.userId,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_USER":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...state.selectedUser,
                    ...action.payload
                },
                userId: action.userId,
            };


        case "KYC_STARTED":
            return {
                ...state,
                isKycNew: action.isKycNew
            }

        case "KYC_CREATED":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...action.payload
                },
                userCreated: true,
                loading: false,
                loading_image: false
            };


        case "KYC_UPDATED":
            return {
                ...state,
                error: false,
                userUpdated: true,
                loading: false,
                loading_image: false,
                //reupdate the selected user here...
                selectedUser: {
                    ...action.payload
                },
                logoutRequired: true
            };

        case "CANCEL_LOGOUT":
            return {
                ...state,
                logoutRequired: false
            };

        case 'ERROR_KYCS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false,
                selectedUser: {
                    ...action.payload
                }
            };

        case "KYC_DROP_FILE":
            // console.log("KYC_DROP_FILE", action.id)
            // console.log('docType', action.docType)

            let newDocs = { ...tempDocs }
            newDocs[action.docType.name] = {
                file: action.file,
                preview: URL.createObjectURL(action.file),
                docType: action.docType
            }
            return {
                ...state,
                tempDocs: newDocs
            }

        case "KYC_REPLACE_FILE":
            //delete tempDoc
            let newReplacedDocs = { ...tempDocs }
            delete newReplacedDocs[action.docType.name]

            //delete docDisplay if available
            let newDocDisplay = { ...state.documentDisplay }
            delete newDocDisplay[action.docType.id]

            return {
                ...state,
                tempDocs: newReplacedDocs,
                documentDisplay: newDocDisplay,
            }

        case "KYC_GET_IMAGES":
            // console.log('KYC_GET_IMAGES', action.imageData, action.payload)



            //update temporary document listing uploader
            let refDocCollection = action.payload.documentTypes
            let refDocId = action.imageData.documentTypeId
            let docTypeRef = _.find(refDocCollection, { 'id': String(refDocId) });

            let newDocReplace = { ...tempDocs }
            // console.log('newDocReplace', action.imageData.documentTypeId, newDocReplace, action.payload.documentTypes)
            // console.log('retrieved docRefType', docTypeRef)

            newDocReplace[docTypeRef.name] = {
                file: action.payload.image,
                docType: docTypeRef
            }

            //replace document image
            let newReplaceImage = { ...state.documentDisplay }
            newReplaceImage[action.imageData.documentTypeId] = {
                image: action.payload.image,
                type: action.payload.type,
                name: docTypeRef.name
            }

            return {
                ...state,
                documentDisplay: newReplaceImage,
                tempDocs: newDocReplace
            }

        case "UPDATE_KYC_STATUS":
            return {
                ...state,
                userKycStatus: action.status
            }

        case "KYCS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "KYC_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_KYC_DATA":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}