export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SUB_LAYOUT = "CHANGE_SUB_LAYOUT";
export const LAYOUT_TYPE = "LAYOUT_TYPE";
export const RESET = "RESET";
export const NAV_BACK_COLOR = "NAV_BACK_COLOR";
export const NAV_BRAND_COLOR = "NAV_BRAND_COLOR";
export const HEADER_BACK_COLOR = "HEADER_BACK_COLOR";
export const RTL_LAYOUT = "RTL_LAYOUT";
export const NAV_FIXED_LAYOUT = "NAV_FIXED_LAYOUT";
export const HEADER_FIXED_LAYOUT = "HEADER_FIXED_LAYOUT";
export const FULL_WIDTH_LAYOUT = "FULL_WIDTH_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

// USER ACTIONS
export const TOPUP = "TOPUP";
export const DEPOSIT = "DEPOSIT";
export const WITHDRAWAL = "WITHDRAWAL";
export const TRANSFER = "TRANSFER";
export const REQUEST = "REQUEST";
export const SEND = "SEND";
export const PAY = "PAY";

export const AIRTIME = "AIRTIME";
export const TAXI = "TAXI";
export const UTILITY = "UTILITY";
export const MERCHANT = "MERCHANT";

export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

export const KYC_DRAFT = "draft";
export const KYC_PROGRESS = "progress";
export const KYC_MISSING = "missing";
export const KYC_APPROVED = "approved";
export const KYC_REJECTED = "rejected";

export const UPDATE_KYC = "update_kyc";

export const ADD_LOYALTY = "ADD_LOYALTY";
export const UPDATE_LOYALTY = "UPDATE_LOYALTY";

export const ADD_REWARD = "ADD_REWARD";
export const UPDATE_REWARD = "UPDATE_REWARD";

//Remittance
export const ADD_TENANT = "ADD_TENANT";
export const UPDATE_TENANT = "UPDATE_TENANT";

export const ADD_TENANT_ACCOUNT = "ADD_TENANT_ACCOUNT";
export const UPDATE_TENANT_ACCOUNT = "UPDATE_TENANT_ACCOUNT";

export const ADD_PAYOUT = "ADD_PAYOUT";
export const UPDATE_PAYOUT = "UPDATE_PAYOUT";

// settings/currency
export const ADD_CURRENCY = "ADD_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";

export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

// customer
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";

// collection
export const ADD_COLLECTION = "CREATE_SCHEDULE";
export const UPDATE_COLLECTION = "UPDATE_SCHEDULE";

export const ADD_PURPOSE = "ADD_PURPOSE";
export const UPDATE_PURPOSE = "UPDATE_PURPOSE";

// accounts/kyc
export const ADD_USER_ACCOUNT_KYC = 'ADD_USER_ACCOUNT_KYC';
export const UPDATE_USER_ACCOUNT_KYC = 'UPDATE_USER_ACCOUNT_KYC';

export const CREATE_SYSTEM_USER = 'CREATE_SYSTEM_USER';
export const UPDATE_SYSTEM_USER = 'UPDATE_SYSTEM_USER';