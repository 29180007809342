let defaultPermissionTemplate = [
    {
        "id": "client-remittance",
        "text": "Client Remittance",
        "state": 1,
        "isLeaf": true,
        "children": [
            {
                "id": "dashboard-page",
                "text": "Dashboard",
                "state": 1,
                "isLeaf": true
            },
            {
                "id": "customer-page",
                "text": "Customers",
                "state": 1,
                "isLeaf": true
            },
            {
                "id": "transaction-page",
                "text": "Transactions",
                "state": 1,
                "isLeaf": true
            }
        ]
    },
    {
        "id": "user-management",
        "text": "User Management",
        "state": 1,
        "isLeaf": true,
        "children": [
            {
                "id": "menu-level-users",
                "text": "Users",
                "state": 1,
                "isLeaf": true,
                "children": [
                    {
                        "id": "create-menu-level-users",
                        "text": "Create",
                        "state": 1,
                        "isLeaf": true
                    },
                    {
                        "id": "view-menu-level-users",
                        "text": "View",
                        "state": 1,
                        "isLeaf": true
                    }
                ]
            },
            {
                "id": "menu-level-roles",
                "text": "Roles & Permissions",
                "state": 1,
                "isLeaf": true,
                "children": [
                    {
                        "id": "create-menu-level-roles",
                        "text": "Create",
                        "state": 1,
                        "isLeaf": true
                    },
                    {
                        "id": "view-menu-level-roles",
                        "text": "View",
                        "state": 1,
                        "isLeaf": true
                    }
                ]
            }
        ]
    },
    {
        "id": "client-settings",
        "text": "Page Settings",
        "state": 1,
        "isLeaf": true,
        "children": [
            {
                "id": "menu-level-currency",
                "text": "Currencies",
                "state": 1,
                "isLeaf": true,
                "children": [
                    {
                        "id": "create-menu-level-currency",
                        "text": "Create",
                        "state": 1,
                        "isLeaf": true
                    },
                    {
                        "id": "view-menu-level-currency",
                        "text": "View",
                        "state": 1,
                        "isLeaf": true
                    }
                ]
            },
            {
                "id": "menu-level-fees",
                "text": "Transaction Fees",
                "state": 1,
                "isLeaf": true,
                "children": [
                    {
                        "id": "create-menu-level-fees",
                        "text": "Create",
                        "state": 1,
                        "isLeaf": true
                    },
                    {
                        "id": "view-menu-level-fees",
                        "text": "View",
                        "state": 1,
                        "isLeaf": true
                    }
                ]
            },
            {
                "id": "menu-level-forex",
                "text": "Transaction forex",
                "state": 1,
                "isLeaf": true,
                "children": [
                    {
                        "id": "create-menu-level-forex",
                        "text": "Create",
                        "state": 1,
                        "isLeaf": true
                    },
                    {
                        "id": "view-menu-level-forex",
                        "text": "View",
                        "state": 1,
                        "isLeaf": true
                    }
                ]
            },
            {
                "id": "menu-level-purposes",
                "text": "Transaction purposes",
                "state": 1,
                "isLeaf": true,
                "children": [
                    {
                        "id": "create-menu-level-purposes",
                        "text": "Create",
                        "state": 1,
                        "isLeaf": true
                    },
                    {
                        "id": "view-menu-level-purposes",
                        "text": "View",
                        "state": 1,
                        "isLeaf": true
                    }
                ]
            }
        ]
    }
]


export default defaultPermissionTemplate