const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: false,
  loading_image: false,
  loaded: false,
  loadingText: "Loading...",
  data: [],
  selectedCustomer: false,
  found: false,
  quote: {
    data: false,
    form: {
      sendingCurrencyId: null,
      receivingBranchId: null,
      amount: null
    }
  },
  transactionData: {
    customerId: null,
    quoteId: null,
    purpose: null,
    remarks: null
  },
  confirmed: false,
  selectedBeneficiary: false,
  receiverAccount: null,
  transactionFormData: null,
  showPaymentForm: false,
  transactionIsPaid: false
};

export default function send_money(state = initState, action) {
  switch (action.type) {
    case "SAVE_TRANSACTION_FORM_DATA":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        showPaymentForm: true,
        transactionIsPaid: false,
        transactionFormData: {
          ...action.data
        }
      };
    case "FETCH_SENT_MONEY_DETAILS":
      return {
        ...state,
        loading: false,
        open: false,
        showPaymentForm: false,
        transactionIsPaid: true,
        confirmed: {
          ...action.data
        }
      };

    case "SET_CUSTOMER":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        transactionData: {
          ...state.transactionData,
          customerId: action.data.customerId
        },
        selectedCustomer: {
          ...action.data
        }
      };

    case "SET_BANK":
      console.log("sendmoney SET_BANK");
      return {
        ...state,
        selectedBank: {
          ...action.data
        }
      };

    case "SEND_MONEY_SET_BENEFICIARY_ID":
      return {
        ...state,
        transactionData: {
          ...state.transactionData,
          beneficiaryId: action.objBeneficiary.id
        },
        selectedBeneficiary: {
          ...action.objBeneficiary
        },
        receiverAccount: {
          ...action.objBeneficiary
        }
      };

    case "SEND_MONEY_CONFIRMED":
      return {
        ...state,
        loading: false,
        transactionIsPaid: true,
        showPaymentForm: false,
        confirmed: {
          ...action.data
        },
        transactionData: {
          ...state.transactionData,
          ...action.form
        }
      };

    case "TOGGLE_SEND_MONEY_MODAL":
      //clear storage when cancelled
      let newModalVisibilityState =
        typeof action.boolHide === "boolean" ? !action.boolHide : !state.open;
      let trData = state.transactionData;
      let quoteData = state.quote;
      let beneficiaryData = state.beneficiary;
      let receiverData = state.receiver;

      let newConfirmedStatus = state.confirmed;
      if (!newModalVisibilityState) {
        trData = {
          ...initState.transactionData,
          customerId: state.selectedCustomer.id
        };
        quoteData = initState.quote;
        beneficiaryData = initState.selectedBeneficiary;
        receiverData = initState.receiverAccount;
        newConfirmedStatus = initState.confirmed;
      }

      return {
        ...state,
        open: newModalVisibilityState,
        //also clear the modal items
        transactionData: trData,
        quote: quoteData,
        selectedBeneficiary: beneficiaryData,
        receiverAccount: receiverData,
        confirmed: newConfirmedStatus
      };

    case "CUSTOMER_SEARCH_FOUND":
      return {
        ...state,
        found: action.customerId
      };

    case "QUOTE_FETCH":
      return {
        ...state,
        loading: false,
        quote: {
          ...state.quote,
          data: action.data,
          form: action.form
        }
      };

    case "ERROR_SEND_MONEY":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false
      };

    case "SEND_MONEY_LOADING":
      return {
        ...state,
        error: false,
        loading: action.boolIsLoading,
        loadingText: action.loadingMsg,
        loading_image: false
      };

    case "SEND_MONEY_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_SEND_MONEY_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      };

    default:
      return state;
  }
}
