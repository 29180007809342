import jwt_decode from "jwt-decode";
import { flattenPermissions } from '../../actions/helpers/permissionHelper'

//need for first render
const initState = {
    accessToken: false,
    error: false,
    reset_success: false,
    errorMsg: "",
    user: {
        firstname: null,
        middleName: null,
        lastname: null,
        email: null,
        phoneNumber: null,
        role: null,
        createdAt: null,
    },
    socialProfile: {},
    loading: false,
    loading_image: false,
    socialMediaLoginLoading: false,
    accountActivated: false
};

export default function auth(state = initState, action) {

    //let defaultUser = initState.user;

    switch (action.type) {

        case "ACCOUNT_ACTIVATED":
            return {
                ...state,
                error: false,
                loading: false,
                accountActivated: true
            }

        case "LOGIN_VIA_SOCIAL":
            return {
                ...state,
                error: false,
                loading: false,
                socialMediaLoginLoading: true,
                socialProfile: { ...action.socialProfile }
            }

        case "SOCIAL_LOGIN_COMPLETE":

            return {
                ...state,
                error: false,
                loading: false,
                socialMediaLoginLoading: false,
                socialProfile: { ...action.socialProfile }
            }

        case "ADMIN_LOGIN":

            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user: {
                    ...action.user
                }
            }

        case "LOGIN":

            var { username, firstName, middleName, lastName, email, createdAt, permissions,
                isCustomer, role, isAdmin, isExpired, temporaryPassword } = action.user.data.attributes;

            var newPermissions = permissions != "" ? permissions : null;
            let loginRefPermissions = flattenPermissions(newPermissions)
            let jwt = jwt_decode(action.auth.accessToken)

            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                jwt,
                user: {
                    ...action.user,
                    id: action.user.data.id,
                    username: username,
                    Firstname: firstName,
                    Middlename: middleName || "",
                    Lastname: lastName,
                    email: email,
                    createdAt: createdAt,
                    permissions: newPermissions && JSON.parse(newPermissions || []),
                    flatPermissions: loginRefPermissions,
                    isCustomer: isCustomer,
                    role: role,
                    isAdmin: isAdmin,
                    isExpired: isExpired,
                    temporaryPassword: temporaryPassword || false
                },

            };

        case "AUTH_LOAD_SESSION": {

            var { username, firstName, middleName, lastName, email, createdAt, permissions,
                isCustomer, role, isAdmin, isExpired, temporaryPassword } = action.user.data.attributes;

            var newPermissions = permissions != "" ? permissions : null;
            let sessionRefPermissions = flattenPermissions(newPermissions)
            let jwt = jwt_decode(action.auth.accessToken)

            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                jwt,
                user: {
                    ...action.user,
                    id: action.user.data.id,
                    username: username,
                    Firstname: firstName,
                    Middlename: middleName || "",
                    Lastname: lastName,
                    email: email,
                    createdAt: createdAt,
                    permissions: newPermissions && JSON.parse(newPermissions || []),
                    flatPermissions: sessionRefPermissions,
                    isCustomer: isCustomer,
                    role: role,
                    isAdmin: isAdmin,
                    isExpired: isExpired,
                    temporaryPassword: temporaryPassword || false
                },
            }
        }

        case "ADMIN_AUTH_LOAD_SESSION":

            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user: {
                    ...action.user
                }
            }

        case "AUTH_LOADING":

            return {
                ...state,
                error: false,
                loading: true
            };

        case "AUTH_FAILED":
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg,
                loading: false
            };

        case "DISMISS_AUTH_ERROR":
            return initState;

        case "LOGOUT":

            let useraccount = localStorage.getItem('username');
            let password = localStorage.getItem('password');
            let rememberme = localStorage.getItem('rememberme');

            localStorage.clear();

            if (rememberme) {
                localStorage.setItem('username', useraccount);
                localStorage.setItem('password', password);
                localStorage.setItem('rememberme', rememberme);
            }

            window.location.href = "/auth/signin";

            return initState;

        default:
            return state;
    }

}