import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    firstLoad: true,
    loaded: false,
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    coh: [],
    selectedUser: {
        name: "",
        description: "",
        updatedAt: "",
        permissions: false,
        kyc: {
            status: false
        }
    },
    userStatusList: [
        'kyc-pending', 'kyc-started', 'kyc-rejected', 'active', 'inactive',
    ],
    userCreated: false,
    userAction: null,
    userId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ],
    dropdownOpen: new Array(10).fill(false)
}

export default function clients_accounts(state = initState, action) {

    switch (action.type) {

        case 'FETCH_ACCOUNT_USERS':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case "SET_INITIAL_LOADED":
            return {
                ...state,
                loaded: false
            }

        case 'USERS_LOADING':
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false,
            };

        case 'USER_LIST_DEFAULT':
        case 'DISMISS_ERROR':
        case 'CLEAR_USER_DATA':
            return initState;

        default:
            return state

    }

}