import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    firstLoad: true,
    loaded: false,
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    coh: [],
    selectedUser: {
        name: "",
        description: "",
        updatedAt: "",
        permissions: false,
        kyc: {
            status: false
        }
    },
    userStatusList: [
        'kyc-pending', 'kyc-started', 'kyc-rejected', 'active', 'inactive',
    ],
    userCreated: false,
    userAction: null,
    userId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ],
    dropdownOpen: new Array(10).fill(false),
    cash_on_hand_submitted: false,
    cash_submission: [],
    cash_collectibles: [],
    cash_collections: [],
    selected_cash_collections: null,
    selected_collectible: null,
    collection_created: false
}

export default function user_account(state = initState, action) {

    switch (action.type) {

        case 'FETCH_USERS':

            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case 'FETCH_AGENTS_COH':

            var agentCoh = [];
            action.data.map(agents => {
                if (agents.cashReceivables.length > 0) {
                    agents.cashReceivables = {
                        ...agents.cashReceivables[0]
                    }
                    agentCoh.push(agents)
                }
                return agents
            });

            return {
                ...state,
                error: false,
                coh: agentCoh,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case "FETCH_A_USER":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...action.payload
                },
                userId: action.userId,
                loading: false,
                loading_image: false
            };

        case "USER_CREATED":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...action.payload
                },
                userCreated: true,
                loading: false,
                loading_image: false
            };

        case "INITIAL_PERMISSIONS":
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    permissions: action.initialPermissions
                }
            }

        case "USER_UPDATED":
            return {
                ...state,
                error: false,
                userUpdated: true,
                loading: false,
                loading_image: false
            };

        case 'ERROR_USERS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "USERS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "USER_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_USER_DATA":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        // Collections
        case "USER_COH_SUBMITTED":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                cash_on_hand_submitted: true
            };

        case 'FETCH_CASH_SUBMISSIONS':

            return {
                ...state,
                error: false,
                cash_submission: action.payload,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case 'SET_USER_LOADED':

            return {
                ...state,
                loaded: action.boolIsLoaded
            };

        case 'FETCH_CASH_COLLECTIBLES':

            return {
                ...state,
                error: false,
                cash_collectibles: action.payload,
                meta: action.meta,
                loading: false,
                loading_image: false,
            };

        case 'FETCH_A_COLLECTIBLES':

            return {
                ...state,
                error: false,
                selected_collectible: action.payload,
                meta: action.meta,
                loading: false,
                loading_image: false,
            };

        case 'FETCH_CASH_COLLECTIONS':

            return {
                ...state,
                error: false,
                cash_collections: action.payload,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case 'FETCH_A_COLLECTIONS':
            return {
                ...state,
                error: false,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                selected_cash_collections: action.payload,
            };

        case 'CLEAR_SELECTED_COLLECTIONS':
            return {
                ...state,
                error: false,
                meta: action.meta,
                loading: false,
                loading_image: false,
                selected_cash_collections: null,
            };

        case 'COLLECTION_CREATED':
        case 'COLLECTION_UPDATED':
        case 'COLLECTION_ITEM_CREATED':

            return {
                ...state,
                error: false,
                meta: action.meta,
                loading: false,
                collection_created: true,
                collection_item_created: true,
            };

        case "SET_INITIAL_LOADED":
            return {
                ...state,
                loaded: false
            }

        default:
            return state

    }

}